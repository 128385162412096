import React from 'react'
import Menu from '../../Toolbar/ToolBar'
import Inicio from '../../Componentes/Inicio/Inicio'
import Nosotros from '../../Componentes/Nosotros/Nosotros'
import Industrias from '../../Componentes/Industrias/Industrias'
import Beneficios from '../../Componentes/Beneficios/Beneficios'
//import Ventajas from '../../Componentes/Ventajas/Ventajas'
import Clientes from '../../Componentes/Clientes/Clientes'
//import Escribenos from '../../Componentes/Escribenos/Escribenos'
import Footer from '../../Footer/Footer';
//import MenuInformacion from '../../ToolBarInformacion/MenuInformacion';
import './home.css';
import Info from '../../Header/Info'
import Contactanos from '../../Componentes/Contactanos/Contactanos'
import { faFacebook, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class Home extends React.Component{
    render(){
        return(
            <div className='App'>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"></link>
                 <a href="https://api.whatsapp.com/send?phone=51986943706&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20sistema%20." class="float" target="_blank">
                    <i class="fa fa-whatsapp my-float"></i> 
                </a>    
                {/*<Info/>*/}       
                <Menu EsComprobante = {false} />                
                <Inicio />
                <Nosotros />
                <Industrias/>
                <Beneficios />
                {/*<Ventajas/>*/}
                <Clientes/>
                {/*<Escribenos />*/}
                <Contactanos/>
                 <Footer />
            </div>
        )
    }
}
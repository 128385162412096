import React from 'react'
import Menu from '../../Toolbar/ToolBar'
import Formulario from '../../Componentes/Formulario/Formulario'
import Footer from '../../Footer/Footer';
import './comprobantes.css'

export default class Comprobantes extends React.Component {
    render() {
        return (
            <div className='comprobantes__content'>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"></link>
                <a href="https://api.whatsapp.com/send?phone=51974643870&amp;text=Hola%21%20no%20puedo%20descargar%20mi%20comprobante%20de%20la%20pagina%20." class="float" target="_blank">
                    <i class="fa fa-whatsapp my-float"></i>
                </a>
                <Menu EsComprobante = {true}/>
                <div className='comprobantes'>
                <Formulario/>
                </div>
                <div className='comprobantes__footer'>
                <Footer />
                </div>
            </div>
        )
    }
}